var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex addresscard align-items-center mb-2",attrs:{"elevation":"1"}},[_c('v-row',{staticClass:"pl-4 pr-2 py-4",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('span',{staticClass:"font-weight-6"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.address.contactName))+" ")]),_c('span',{staticClass:"text-caption ml-2 mr-4"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.address.contactNo))+" ")]),(_vm.address.default)?_c('v-chip',{attrs:{"x-small":"","color":"blue","text-color":"white"}},[_vm._v(" Default ")]):_vm._e()],1)]),_c('div',[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.address.address))+" ")])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('router-link',{attrs:{"to":{
          name: 'editaddress',
          query: { addid: _vm.id },
        }}},[_c('v-icon',{staticClass:"text--disabled"},[_vm._v("mdi-pencil")])],1),_c('v-icon',{staticClass:"error--text",on:{"click":function () {
            _vm.showDialog = true;
          }}},[_vm._v("mdi-delete")])],1)],1),(_vm.showDialog)?_c('confirmation-dialog',{attrs:{"title":"Remove address","body":"Are you sure to remove this ?"},on:{"cancel":function () {
        _vm.showDialog = false;
      },"confirm":_vm.removeAddress},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }

<template>
  <v-dialog v-model="show" width="500" transition="dialog-bottom-transition">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ body }}
      </v-card-text>
      <v-card-actions class="align-content-end">
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('confirm')"> {{ 'Confirm' }}</v-btn>
        <v-btn color="" text @click="$emit('cancel')"> {{ 'Cancel' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    body: String,
    confirmText: String,
    cancelText: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
</style>
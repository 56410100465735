<template>
  <div class="min-vh-100">
    <toolbar
      customRoute="/menu"
      bgcolor="white"
      hasDrawer="true"
      title="Addresses"
      @open-drawer="$emit('open-drawer')"
    ></toolbar>
    <v-main>
      <CentralLoader v-if="isloading"></CentralLoader>
      <v-container v-else>
        <template v-if="addressList">
          <AddressCard
            class="mb-2"
            v-for="address in addressList"
            :key="address.id"
            :id="address.id"
            :address="address"
          />
        </template>
        <template v-else>
          <p class="text-center">No Data Found</p>
        </template>
      </v-container>
    </v-main>
    <router-link :to="{ name: 'addaddress' }"
      ><v-btn
        bottom
        fixed
        :style="{ left: '50%', transform: 'translateX(-50%)' }"
        color="primary"
      >
        Add Address
      </v-btn>
    </router-link>
  </div>
</template>

<script>
import toolbar from '@/components/partials/BackToolbar';
import AddressCard from '@/components/Addresses/Addresscard';
import CentralLoader from '@/components/Loaders/CentralLoader';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    toolbar,
    AddressCard,
    CentralLoader,
  },
  data: function () {
    return {
      isloading: false,
    };
  },
  methods: {
    ...mapActions('customer', ['getAddresses']),
  },
  computed: {
    ...mapState('customer', ['addressList']),
  },
  created() {
    this.isloading = true;
    this.getAddresses()
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.isloading = false;
      });
  },
};
</script>

<style></style>

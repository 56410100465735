<template>
  <v-card class="d-flex addresscard align-items-center mb-2" elevation="1">
    <v-row no-gutters align="center" justify="center" class="pl-4 pr-2 py-4">
      <v-col cols="11">
        <v-row no-gutters>
          <div>
            <span class="font-weight-6">
              {{ address.contactName | capitalize }}
            </span>
            <span class="text-caption ml-2 mr-4">
              {{ address.contactNo | capitalize }}
            </span>
            <v-chip
              v-if="address.default"
              x-small
              color="blue"
              text-color="white"
            >
              Default
            </v-chip>
          </div>
        </v-row>
        <div>
          <span class="text-subtitle-2">
            {{ address.address | capitalize }}
          </span>
        </div>
      </v-col>
      <v-col cols="1">
        <router-link
          :to="{
            name: 'editaddress',
            query: { addid: id },
          }"
        >
          <v-icon class="text--disabled">mdi-pencil</v-icon>
        </router-link>
        <v-icon
          class="error--text"
          @click="
            () => {
              showDialog = true;
            }
          "
          >mdi-delete</v-icon
        >
      </v-col>
    </v-row>
    <confirmation-dialog
      v-if="showDialog"
      v-model="showDialog"
      title="Remove address"
      body="Are you sure to remove this ?"
      @cancel="
        () => {
          showDialog = false;
        }
      "
      @confirm="removeAddress"
    />
  </v-card>
</template>

<script>
import ConfirmationDialog from '../DialogModals/ConfirmationDialog';
import { mapActions } from 'vuex';

export default {
  components: {
    ConfirmationDialog,
  },
  props: ['id', 'name', 'address'],
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    ...mapActions('customer', ['deleteAddress', 'getAddresses']),
    async removeAddress() {
      await this.deleteAddress({ id: this.id });
      await this.getAddresses();
      this.showDialog = false;
    },
  },
};
</script>

<style>
@media screen and (max-width: 375px) {
  .addresscard .addresstitle {
    font-size: 12px;
  }
  .addresscard i {
    font-size: 18px !important;
  }
  .addresscard .addresshelper {
    font-size: 8px !important;
  }
  .addresscard h6 {
    font-size: 14px;
  }
}
@media screen and (max-width: 320px) {
  .addresscard .addresstitle {
    font-size: 10px;
  }
  .addresscard i {
    font-size: 16px !important;
  }
  .addresscard .addresshelper {
    font-size: 7px !important;
  }
  .addresscard h6 {
    font-size: 12px;
  }
}
</style>
